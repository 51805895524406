<template>
        <div class="card flex-colum more" :class="(alarm ? 'alarm' : '')">Блок {{ block.name }}
            <div class="block flex-colum">
                <div class="info flex">
                     <div class="red-block">
                       <header>Давление подачи</header>
                       <input v-if="admin" type="number" step="0.1" v-model="ins">
                       <div v-else class="data in">{{ block.in }}</div>
                     </div>
                     <div class="blue-block">
                       <header>Давление обратки </header>
                       <input v-if="admin" v-model="out">
                       <div v-else class="data out">{{ block.out }}</div>
                     </div>
                 </div>
                 <div class="message flex">
                        <svg role="img" ><use xlink:href="#exclamation-triangle-fill"/></svg>
                        <span class="datainfo">{{ mess }}</span><span class="data">{{ val }}</span> 
                 </div>
                 <p>состояние задвижек</p>
                 <div>
                   <div class="flex checks">
                        <label class="flex">
                            <div class="checkvalue flex" :class="block.valve&&block.valve.in?'on':'off'"><b></b></div>
                        </label>
                        <label class="flex">
                            <div class="checkvalue flex" :class="block.valve&&block.valve.out?'on':'off'"><b></b></div>
                        </label>
                   </div>
                 </div>
             </div>
             
            <div class="block flex-colum">
                <div class="flex">
                     <div>Уставка <br>подачи</div>
                     <div class="flex-colum">
                          Нижняя граница
                             <ConfigValue v-model:model="config.in.min"></ConfigValue>

                          Верхняя граница 
                             <ConfigValue v-model:model="config.in.max"></ConfigValue>
                     </div>
                </div>
                <div class="flex">
                     <div>Уставка <br>обратки</div>
                     <div class="flex-colum">
                        Нижняя граница
                             <ConfigValue v-model:model="config.out.min" ></ConfigValue>

                          Верхняя граница
                             <ConfigValue v-model:model="config.out.max"></ConfigValue>
                     </div>
                </div>
            </div>
        </div>
</template>

<script>
import ConfigValue from './ConfigValue.vue'
import { toRaw } from 'vue'

export default {
  name: 'BlockItem',
  components: { ConfigValue },
  props: ['block'],
  data() {
      return { config: this.block.config, ins:this.block.in, out:this.block.out, admin:false}
    },

  created(){ 
        if( window.location.search && window.location.search=='?4Ctrl' ){
           this.admin = true;
           this.$watch('ins', ()=>{ this.save(this.config)}, {deep: true});
           this.$watch('out', ()=>{ this.save(this.config)}, {deep: true});
        }
        this.$watch('config', this.save, {deep: true});

  },
  computed: {
     alarm(){
           let val = false;
           if( this.block.in > this.config.in.max ) val = { val:this.block.in, err: this.block.in+' > '+this.config.in.max, text:'Давление выше нормы' } ;  
           else if( this.block.in < this.config.in.min ) val = { val:this.block.in, err: this.block.in+' < '+this.config.in.min, text:'Давление ниже нормы'};
           if( this.block.out > this.config.out.max ) val = { val: this.block.out, err: this.block.out+' > '+this.config.out.max, text:'Давление выше нормы' } ;  
           else if( this.block.out < this.config.out.min ) val = { val:this.block.out, err: this.block.out+' < '+this.config.out.min, text:'Давление ниже нормы'};
           return val;
     },
      val(){
           return this.alarm ? this.alarm.val : ''; 
      },
      mess(){
           return this.alarm ? this.alarm.text : 'Давление в норме';
      },
  },
  methods:{
      save(newVal){
            let data = {id:this.block.id, config: toRaw(newVal), admin: this.admin?{in:this.ins, out:this.out}:false };
            this.$emit('save', data);
      }
  }
}
</script>


