<template>
  <div v-if="info" class="flex">
  <BlockItem v-for="(i,n) in info.data" :key="n" :block="i" @save="save"></BlockItem>
  </div>
</template>

<script>
import BlockItem from './components/BlockItem.vue'

export default {
  name: 'App',
  components: {
    BlockItem
  },
  data() { return { 
        info: null,
        host: window.CONFIG.host,
        login: !window.CONFIG.login || localStorage.getItem('token'),
        loading:false,
        Timeout:null
        } 
    },
  created: function(){
      this.load();
  },
  methods:{

     load: function(){
        if( this.loading ) return;
        this.loading = true;
        var _this = this;
        this.GET('index.php').then(function(j){
             _this.info = j;
             _this.loading = false;
             _this.Timeout = setTimeout( _this.load, 6*1000);
        }) 
     }, 
      
     save(newVal){
         if(this.Timeout) clearTimeout(this.Timeout);
         console.log('данные обновились!', newVal);
         this.POST('save.php', newVal);
         this.load();
     }, 
      
      
      url:function(url){
        return this.host+'api/'+url+'';
      },
      post: async function(action = '', data = {}, method='POST') {
        // Значения по умолчанию обозначены знаком *
          let url = this.url(action);
          
          var o = {
              method: method, // *GET, POST, PUT, DELETE, etc.
              mode: 'cors', // no-cors, cors, *same-origin
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'same-origin', // include, *same-origin, omit
              headers: { 'Accept': '*/*' },
          }

          
          if( method == 'FORM' ){
              //o.headers['Content-Type'] =  'multipart/form-data';
              o.body = data;
              o.method = 'POST';
          }
          else{
             o.headers['Content-Type'] = 'application/json;charset=utf-8';
             if(data && method!='GET'){
              if(typeof(data)=='string') o.body = data; 
              else o.body = JSON.stringify(data);
             }
          }
          //console.log(method, url, o);
          //if( method == 'POST' ) return;
          try{
              let res = await fetch(url, o);
              var json = await res.json();
              if(json){
                  if( json.errors ){
                     for(var ename in json.errors) for(var i in json.errors[ename]) this.Error(json.errors[ename][i], ename);
                     return false;
                  }else{
                    if( json.message ){
                       this.Message(json.message);
                    }
                    return  json;
                  }
              }else{
                    this.Error('Не удалось получить данные', 'request:'+url);
                    return false;
              } 
          } catch (error) {
               this.Error(error, 'request:'+url);
          }
      },
      POST: function(url, data) {
          return this.post(url,data,'POST');
      },
      GET: function(url) {
          return this.post(url,null,'GET');
      },
      Error: function(mess, type=false) {
          console.log('--- ОШИБКА ----', mess, type);
      },
      

  }

}
</script>
