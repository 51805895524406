<template>

                          <div class="form-group flex fill">
                             <input type="number" size=4 v-model.number="val"><button @click="minus">-</button><button @click="plus">+</button>
                          </div>
                     
</template>

<script>
export default {
  name: 'ConfigValue',
  props: ['model'],
  data() {

      return { step: 0.1}
    },
  watch: { 
        block(newVal) {
          this.val = newVal;
        }
  },
  computed: {
      val: {
          get() {
              return this.model
          },
          set(newValue) {
              this.$emit('update:model', newValue)
          },
      },
  },
  methods:{

       plus(){
           this.val=parseFloat( (this.val+ this.step).toFixed(2) );
       },
       minus(){
           this.val=parseFloat( (this.val-this.step).toFixed(2) );
       },
  }

}
</script>


